<template>
  <article v-loading="loading">
    <RouterNav :name="unitName"></RouterNav>
    <header class="header">
      <div class="left">
        <div class="line"></div>
        <div class="unit-name" v-html="unitName"></div>
      </div>
      <div class="all-selected-btn" @click="allSelectedSwitch">{{allSelected ? '全不选' : '全选'}}</div>
    </header>
    <section class="container">
      <ul class="content">
        <li class="list" v-for="(item, index) in list" :key="item.id">
          <div>{{item.name}}</div>
          <xybutton
              :type="item.selected ? 'del' : 'add'"
              @click="selectWord(index)"
          >{{item.selected ? '移除' : '添加'}}
          </xybutton>
        </li>
      </ul>
    </section>
    <footer class="footer">
      <div>
        <div>请选择练习模式：</div>
        <ul class="type-list">
          <li :class="['list', item.selected ? 'selected-list' : '']" v-for="(item, index) in typeList" :key="item.id"
              @click="typeSelect(index)">{{item.name}}
          </li>
        </ul>
      </div>
      <div class="right">
        <div>预计耗时：{{time}}分钟</div>
        <div class="submit-btn" @click="submit">确定({{selectedList.length}})</div>
      </div>
    </footer>

    <Trolley
        @listState="listState"
    ></Trolley>
  </article>
</template>

<script>
  import RouterNav from '@/components/RouterNav.vue'
  import Trolley from './components/trolley.vue'

  export default {
    name: 'unitWord',
    components: {
      RouterNav,
      Trolley
    },
    data() {
      return {
        loading: false,
        id: '',
        unitName: '',
        list: [],
        selectedList: [],
        typeList: [{
          id: 1,
          name: '朗读'
        }, {
          id: 2,
          name: '看词选义'
        }, {
          id: 3,
          name: '看义选词'
        }, {
          id: 4,
          name: '听音拼写'
        }],
        selectedTypeLength: 0
      }
    },
    computed: {
      allSelected: {
        get: function () {
          return this.selectedList.length === this.list.length
        },
        set: function (newValue) {
          const {list} = this
          const selectedList = []
          for (let i = 0, len = list.length; i < len; i += 1) {
            list[i].selected = newValue
            if (newValue) {
              const obj = {
                id: list[i].id,
                name: list[i].name
              }
              selectedList.push(obj)
            }
          }
          this.selectedList = selectedList
        }
      },
      time: function () {
        const {selectedTypeLength} = this
        const {length} = this.selectedList
        // return Math.ceil(length * 6 * selectedTypeLength / 60)
        return length * 6 * selectedTypeLength / 60
      }
    },
    watch: {
      typeList: {
        handler: function (val, old) {
          let selectedTypeLength = 0
          for (let i = 0, len = val.length; i < len; i += 1) {
            selectedTypeLength += val[i].selected ? 1 : 0
          }
          this.selectedTypeLength = selectedTypeLength
        },
        deep: true
      }
    },
    created() {
      this.id = this.$route.query.id
      this.unitName = this.$route.query.name
      this.flag = Number(this.$route.query.flag)
      this.moduleName = this.$route.query.moduleName
      this.moduleId = this.$route.query.moduleId
      this.getWordsByUnitId()
    },
    methods: {
      listState() {
        if (!this.$store.state.selectedWorkList[this.id]) {
          this.selectedList = []
          const {typeList} = this
          for (let i = 0, len = typeList.length; i < len; i += 1) {
            typeList[i].selected = false
          }
          const {list} = this
          for (let i = 0, len = list.length; i < len; i += 1) {
            list[i].selected = false
          }
        }
      },
      getWordsByUnitId() {
        this.loading = true
        const {id} = this
        this.$axios({
          method: 'post',
          url: this.$urls.getWordsByUnitId,
          data: {
            id
          }
        }).then((response) => {
          if (response.state === '11') {
            const {data} = response
            const {selectedWorkList} = this.$store.state
            if (selectedWorkList[id]) {
              const {wordList} = selectedWorkList[id]
              for (let j = 0, len = wordList.length; j < len; j += 1) {
                for (let k = 0, len = data.length; k < len; k += 1) {
                  if (wordList[j].id === data[k].id) {
                    data[k].selected = true
                    const obj = {}
                    obj.id = data[k].id
                    obj.name = data[k].name
                    this.selectedList.push(obj)
                    break
                  }
                }
              }
              const {typeList} = this
              let selectedTypeLength = 0
              const {typeList: typeList2} = selectedWorkList[id]
              for (let j = 0, len = typeList.length; j < len; j += 1) {
                for (let k = 0, len = typeList2.length; k < len; k += 1) {
                  if (typeList[j].id === typeList2[k].id) {
                    typeList[j].selected = true
                    selectedTypeLength += 1
                    break
                  }
                }
              }
              this.selectedTypeLength = selectedTypeLength
            }
            this.list = data
          }
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
      },
      selectWord(index) {
        const {list} = this
        const {id} = list[index]
        let {selected} = list[index]
        const {selectedList} = this
        if (selected) {
          for (let i = 0, len = selectedList.length; i < len; i += 1) {
            if (selectedList[i].id === id) {
              selectedList.splice(i, 1)
              break
            }
          }
        } else {
          const {name} = list[index]
          const obj = {
            id,
            name
          }
          selectedList.push(obj)
        }
        this.$set(this.list[index], 'selected', !selected)
      },
      allSelectedSwitch() {
        this.allSelected = !this.allSelected
      },
      typeSelect(index) {
        const {typeList} = this
        typeList[index].selected = !typeList[index].selected
        this.$set(this.typeList, index, typeList[index])
      },
      submit() {
        const {selectedList} = this
        if (selectedList.length < 1) {
          this.$alert('至少选择1个单词', '提示', {
            confirmButtonText: '确定'
          })
          return
        }
        if (this.selectedTypeLength === 0) {
          this.$alert('请选择练习模式', '提示', {
            confirmButtonText: '确定'
          })
          return
        }
        const {typeList} = this
        const arr = []
        for (let i = 0, len = typeList.length; i < len; i += 1) {
          if (typeList[i].selected) {
            arr.push(typeList[i])
          }
        }
        const obj = {
          id: this.id,
          name: this.unitName,
          typeList: arr,
          wordList: selectedList,
          indexModuleName: '智能单词',
          moduleName: this.moduleName,
          flag: this.flag,
          time: this.time,
          moduleId: this.moduleId
        }
        this.$store.commit('listAdd', obj)
        this.$router.back()
      }
    }
  }
</script>

<style scoped lang="scss">
  .header {
    @include flex(space-between);
    background-color: #fff;
    height: 66px;
    padding-right: 28px;
    margin-bottom: 10px;

    .left {
      @include flex;
      @include font(17px, #333);

      .line {
        background-color: #309AF2;
        width: 4px;
        height: 14px;
        margin-right: 24px;
      }
    }

    .all-selected-btn {
      background-color: #F6F6F6;
      width: 90px;
      height: 34px;
      line-height: 34px;
      @include font(15px, #309AF2, center);
      cursor: pointer;
    }
  }

  .container {
    background-color: #fff;
    height: calc(100vh - 297px);
    padding: 10px 28px;
    overflow: auto;
    position: relative;
    z-index: 99;

    .content {
      padding: 0 28px;

      .list {
        @include flex(space-between);
        height: 60px;
        border-bottom: 1px solid #F1F1F1;
        font-size: 17px;
      }
    }
  }

  .footer {
    @include flex(space-between);
    background-color: #fff;
    height: 100px;
    padding: 0 28px;
    box-shadow: 0 -6px 15px 0 rgba(0, 0, 0, .06);
    font-size: 13px;
    position: relative;
    z-index: 100;

    .type-list {
      @include flex(flex-start);
      margin-top: 10px;

      .list {
        background-color: #F6F6F6;
        height: 28px;
        line-height: 28px;
        padding: 0 14px;
        border: 1px solid #F6F6F6;
        border-radius: 4px;
        margin-right: 10px;
        @include font(14px, #999999, center);
        cursor: pointer;

        &:hover {
          color: #333333;
        }
      }

      .selected-list {
        background-color: #EAF4FD;
        color: #309AF2;
        border-color: #309AF2;
      }
    }

    .right {
      text-align: right;
    }

    .submit-btn {
      background: linear-gradient(to right, #6DBBFC, #309AF2);
      width: 160px;
      height: 40px;
      line-height: 40px;
      @include font(15px, #fff, center);
      border-radius: 4px;
      margin-top: 10px;
      cursor: pointer;

      &:active {
        background: #309AF2;
      }
    }
  }
</style>
